import { useMemo } from "react";
import "./Marker02Icon.css";

const Marker02Icon = ({
  dimension,
  marker02IconHeight,
  marker02IconPosition,
  marker02IconMaxWidth,
  marker02IconOverflow,
}) => {
  const marker02IconStyle = useMemo(() => {
    return {
      height: marker02IconHeight,
      position: marker02IconPosition,
      maxWidth: marker02IconMaxWidth,
      overflow: marker02IconOverflow,
    };
  }, [
    marker02IconHeight,
    marker02IconPosition,
    marker02IconMaxWidth,
    marker02IconOverflow,
  ]);

  return (
    <img
      className="marker-02-icon"
      alt=""
      src={dimension}
      style={marker02IconStyle}
    />
  );
};

export default Marker02Icon;
