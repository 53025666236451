import "./SocialMediaContainer.css";

const SocialMediaContainer = ({ home05 }) => {
  return (
    <div className="content7">
      <div className="redes-sociales3">Redes sociales</div>
      <div className="ponte-en-contacto">
        Ponte en contacto con nosotros a través de los siguientes medios:
      </div>
      <div className="frame-parent2">
        <div className="location-parent">
          <a href="https://local.mercadolibre.cl/MLC-1459563001-en-venta-hostal-cerca-del-centro-de-calama-_JM" className="location">
            <img className="home-05-icon" alt="" src={home05} />
            <b className="portal-inmobiliario">Portal inmobiliario</b>
          </a>
          <div className="location">
            <img className="home-05-icon" alt="" src="/marker02.svg" />
            <b className="portal-inmobiliario">
              Av. Pdte. Eduardo Frei Montalva 8771
            </b>
          </div>
          <a className="location" href="https://wa.me/56961848508?text=Hola%2C%20me%20gustar%C3%ADa%20obtener%20m%C3%A1s%20informaci%C3%B3n" >
            <img className="home-05-icon" alt="" src="/whatsapp.svg" />
            <b className="portal-inmobiliario">
              BGL (Bodegas Generales De Leasing)
            </b>
          </a>
        </div>
        <div className="phone-parent">
          <a className="mail" href="tel:+56223772910"  >
            <img className="home-05-icon" alt="" src="/phone.svg" />
            <b className="portal-inmobiliario">(+56-2) 2377 2910</b>
          </a>
          <div className="phone3">
            <img className="home-05-icon" alt="" src="/clock01.svg" />
            <b className="portal-inmobiliario">
              <p className="lunes-y-viernes">Lunes y Viernes 09:00 a 14:00</p>
              <p className="lunes-y-viernes">y 14:45 a 17:45</p>
            </b>
          </div>
          <a href="mailto:bgl@bgl.cl" className="mail">
            <img className="home-05-icon" alt="" src="/email.svg" />
            <b className="portal-inmobiliario">bgl@bgl.cl</b>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaContainer;
