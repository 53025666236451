import "./Header.css";

const Header = () => {
  return (
    <div className="header2">
      <div className="header-12">
        <div className="sections2">
        <a href="https://www.bgl.cl" target="_blank" rel="noreferrer" >
          <img className="image-97-icon2" alt="" src="/image-97@2x.png" />
        </a>
          <div className="redes-sociales2">
            <a href="https://www.instagram.com/bgl.cl/" >
              <img className="instagram-icon2" alt="" src="/instagram.svg" />
            </a>
            <a href="https://wa.me/56961848508" >
              <img className="instagram-icon2" alt="" src="/whatsapp.svg" />
            </a>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Header;
