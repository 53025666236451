import React, { useState } from 'react';
import './MyContactForm.css';
import { sendEmail } from "../api/api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const MyContactForm = () => {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [comentario, setComentario] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [nameButton, setNameButton] = useState('Enviar');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setNameButton('Enviando...');
    const emailData = {
      name: nombre,
      email: email,
      phone: telefono,
      message: comentario,

    }

    try {
      const response = await sendEmail(emailData);
      console.log('Email sent successfully', response);
      toast.success(response.message);
    } catch (error) {
      console.error('Error sending email', error);
      toast.error(error.message);
    }
    setNombre('');
    setEmail('');
    setTelefono('');
    setComentario('');
    setDisabled(false);
    setNameButton('Enviado');
  };

  return (
    <div className="formulario-container">
       <ToastContainer />
     

      <form onSubmit={handleSubmit}>
        
      <h1>Quiero que me contacten</h1>

        <div className="input-group">
          <label htmlFor="nombre">Nombre</label>
          <input
            id="nombre"
            type="text"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            placeholder="Escriba su nombre aquí"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Escriba su email aquí"
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="telefono">Teléfono</label>
          <input
            id="telefono"
            type="tel"
            value={telefono}
            onChange={(e) => setTelefono(e.target.value)}
            placeholder="Escriba su teléfono aquí"
            required
          />
        </div>
        <div className="input-group textarea-full">
          <label htmlFor="comentario">Comentario</label>
          <textarea
            id="comentario"
            value={comentario}
            onChange={(e) => setComentario(e.target.value)}
            placeholder="Escriba un comentario aquí"
            required
          />
        </div>
        <button disabled={disabled} type="submit">{nameButton}</button>
      </form>
    </div>
  );
};

export default MyContactForm;
