import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import './ImagesSlider.css';



const ImageSlider = ({ images }) => {


  const settings = {
    dots: false,
    className: "center",
    infinite: true,
    centerPadding: "60px",
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };




  return (
    <>
    <div className="image-slider-container" >
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className='images-wrapper' >
            <img
              src={image}
              alt={`Imagen ${index + 1}`}
              onClick={() => openLightbox(index)}
            />
          </div>
        ))}
      </Slider>
    </div>
  
    </>
  );
};

ImageSlider.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  interval: PropTypes.number,
};

export default ImageSlider;
