import Marker02Icon from "./Marker02Icon";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="all-content-inner2">
      <div className="block-1-parent">
        <div className="block-13">
          <img className="image-97-icon3" alt="" src="/image-97@2x.png" />
          <div className="location1">
            <Marker02Icon
              dimension="/marker02.svg"
              marker02IconHeight="20px"
              marker02IconPosition="relative"
              marker02IconMaxWidth="100%"
              marker02IconOverflow="hidden"
            />
            <div className="santiago-chile">Santiago, Chile</div>
          </div>
          <div className="redes-sociales4">
            <img className="instagram-icon3" alt="" src="/instagram.svg" />
            <img className="instagram-icon3" alt="" src="/facebook.svg" />
            <img className="instagram-icon3" alt="" src="/whatsapp.svg" />
          </div>
        </div>
        <div className="block-3">
          <b className="casa-matriz">Casa Matriz</b>
          <div className="phone4">
            <img className="mail-01-icon" alt="" src="/phone.svg" />
            <div className="santiago-chile">+56 55 256 0380</div>
          </div>
          <div className="phone4">
            <div className="vuesaxlinearmobile-wrapper">
              <img
                className="vuesaxlinearmobile-icon"
                alt=""
                src="/vuesaxlinearmobile.svg"
              />
            </div>
            <div className="santiago-chile">+56 9 40 06 99 34</div>
          </div>
          <div className="phone4">
            <img className="mail-01-icon" alt="" src="/mail01.svg" />
            <div className="santiago-chile">ventas@oicomp.cl</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
