import React, { useState } from 'react';
import './MyContactForm.css';
import './OfferForm.css';
import { sendEmail } from "../api/api";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const OfferForm = (props) => {
  const [nombre, setNombre] = useState('');
  const [email, setEmail] = useState('');
  const [telefono, setTelefono] = useState('');
  const [price, setPrice] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [nameButton, setNameButton] = useState('Enviar');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisabled(true);
    setNameButton('Enviando...');
    const emailData = {
      name: nombre,
      email: email,
      phone: telefono,
      offer: price
    }

    try {
      const response = await sendEmail(emailData);
      console.log('Email sent successfully', response);
      toast.success(response.message);
    } catch (error) {
      console.error('Error sending email', error);
      toast.error(error.message);
    }
    setNombre('');
    setEmail('');
    setTelefono('');
    setDisabled(false);
    setNameButton('Enviado');
  };


   const toggleModal = () => {
    
    setShowModal(!showModal);
  };

    return (
      <>
        <button
            onClick={toggleModal}
            className="offer-button"
          >
            Ofertar
          </button>
        {showModal && (
          <div className="modal-background" onClick={toggleModal}>
             <ToastContainer />
            <div className="modal-container" onClick={(e) => e.stopPropagation()}>
            <button className='close' onClick={toggleModal}>X</button>

              <form onSubmit={handleSubmit}>
                <h1>Quiero que me contacten</h1>
                <div className="input-group">
                  <label htmlFor="nombre">Nombre</label>
                  <input
                    id="nombre"
                    type="text"
                    value={nombre}
                    onChange={(e)=>setNombre(e.target.value)}
                    placeholder="Escriba su nombre aquí"
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="email">Email</label>
                  <input
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    placeholder="Escriba su email aquí"
                    required
                  />
                </div>
                <div className="input-group">
                  <label htmlFor="telefono">Teléfono</label>
                  <input
                    id="telefono"
                    type="tel"
                    value={telefono}
                    onChange={(e)=>setTelefono(e.target.value)}
                    placeholder="Escriba su teléfono aquí"
                    required
                  />
                </div>
                <div className="input-group price-input">
                  <label htmlFor="price">Precio</label>
                  <input
                    id="price"
                    type="number"
                    value={price}
                    onChange={(e)=>setPrice(e.target.value)}
                    placeholder="Monto en UF"
                    required
                  />
                </div>
                <div className="frame-div">
                    <b className="usd-16950-uf2">USD $16.950 UF</b>
                    <div className="precio-de-venta1">$ 624.000.859.50 CLP</div>
                </div>
                <button disabled={disabled} type="submit">{nameButton}</button>
              </form>
            </div>
          </div>
        )}
      </>
    );
  }


export default OfferForm;
