import axios from 'axios';

const API_BASE_URL = 'https://api.bgl.cl/'; // Replace with your API endpoint
//const API_BASE_URL = 'http://localhost:8000/';
const api = axios.create({
  baseURL: API_BASE_URL,
});

export const sendEmail = async (emailData) => {
  try {
    const response = await api.post('/api/web/property_contact', emailData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
